.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    padding: 20px 0;
}

.header > div {
    width: 20%;
}

.header > div:nth-child(2) {
    width: 60%;
}

.botName {
    color: #A8A8A8;
    font-size: 13px;
}

.botImg > img{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    float: right;
    margin-right: 10px;
}
