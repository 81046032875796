@font-face {
  font-family: 'customFont';
  src: url('../public/assets/font/Onest-VariableFont_wght.ttf')
}

body {
  margin: 0;
  font-family: 'customFont' , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: url('../public/assets/images/background/man_back.png') no-repeat fixed !important;*/
  background-size: cover  !important;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

.MuiDialog-paper {
  width: 100%;
  height: 100%;
  max-width: 480px;
  margin: auto;
}

.loading-points {
  height: 15px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 10%;
  z-index: 1200;
  bottom: 100px;
}

.loading-points:before, .loading-points:after, .loading-points span {
  content: '';
  border: 5px solid #868FDD;
  background: #868FDD;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50px;
  margin: 3px;
  animation: 0.6s fade-point linear infinite;
  max-width: 10px;
 }

.loading-points:before {
  animation-delay: 0s;
}

.loading-points span {
    animation-delay: .2s;
  }

.loading-points:after {
   animation-delay: .4s;
 }

@keyframes fade-point {
  50% {  opacity: 0  }
}


@keyframes fade {
  from { opacity: 0; }
  to { opacity: 100%; }
}

@keyframes t{
  from {background-size:0 200%}
}
@keyframes b{
  50% {background-position:0 -100%,0 0}
}
