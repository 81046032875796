img{
    margin-right: 4px;
}

.imgChoices {
    filter: brightness(0) invert(1);
}

.btnChoices {
    width: 120px !important;
    height: 34px;
    font-weight: 300 !important;
    font-size: 15px !important;
    color: #262626 !important;
    border: 1px rgba(255, 255, 255, 0.67) solid !important;
    border-radius: 16px !important;
    margin: 12px !important;
    line-height: 1 !important;
}

.btnChoices:nth-child(1) {background: #ECD5FF !important;}
.btnChoices:nth-child(2) {background: #D5EFFF !important;}
.btnChoices:nth-child(3) {background: #FFE3D0 !important;}
.btnChoices:nth-child(4) {background: #F9EDE3 !important;}
.btnChoices:nth-child(5) {background: #fff8f3 !important;}
.btnChoices:nth-child(6) {background: #eef8ff !important;}
.btnChoices:nth-child(7) {background: #D0C3FF !important;}

.hair-types {
    max-width: 350px;
    margin: auto;
    .hair-type-box {
        display: flex;
        justify-content: space-between;
        svg {
            border: 1px #BFBFBF solid;
            border-radius: 20px;
            width: 24px;
            height: 140px;
            padding: 10px 5px;
            rect, path {
                fill: none;
                stroke: #BFBFBF;
                stroke-width: 2px;
            }
        }
    }
}

.Epaisseur {
    input[type=range] {
        -webkit-appearance: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        width: 300px;
        height: 3px;
        background: #aba9a9;
        border: none;
        border-radius: 3px;
    }
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #B8A8F3;
        margin-top: -5px;
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: #ccc;
    }
    input[type=range] {
        /* fix for FF unable to apply focus style bug  */
        border: 1px solid white;
        /*required for proper track sizing in FF*/
        width: 300px;
    }
    input[type=range]::-moz-range-track {
        width: 300px;
        height: 5px;
        background: #ddd;
        border: none;
        border-radius: 3px;
    }
    input[type=range]::-moz-range-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #B8A8F3;
    }

    /*hide the outline behind the border*/
    input[type=range]:-moz-focusring {
        outline: 1px solid white;
        outline-offset: -1px;
    }
    input[type=range]:focus::-moz-range-track {
        background: #ccc;
    }

    /* for ie */

    input[type=range]::-ms-track {
        width: 300px;
        height: 5px;

        /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
        background: transparent;

        /*leave room for the larger thumb to overflow with a transparent border */
        border-color: transparent;
        border-width: 6px 0;

        /*remove default tick marks*/
        color: transparent;
    }
    input[type=range]::-ms-fill-lower {
        background: #777;
        border-radius: 10px;
    }
    input[type=range]::-ms-fill-upper {
        background: #ddd;
        border-radius: 10px;
    }
    input[type=range]::-ms-thumb {
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #B8A8F3;
    }
    input[type=range]:focus::-ms-fill-lower {
        background: #888;
    }
    input[type=range]:focus::-ms-fill-upper {
        background: #ccc;
    }
}

.Longueur {
    
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #FFEB00;
        box-shadow: 0 0 7px 1px #000000;
    }

    input[type=range]::-webkit-slider-thumb:hover, input[type=range]::-webkit-slider-thumb:focus {
        transform: scale(2); /* Appliquer l'effet de zoom au survol */
    }
}
