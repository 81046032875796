 #container_vid {
     position: fixed;
     transition: transform .5s ease-in;
     transform: translateY(1000%);
     will-change: transform;
     top: 80px;
     right: 0;
     left: 0;
     z-index: 9999;
     width: 100%;
     max-width: 480px;
     margin: auto;
     background: #fff;
     height: 80vh;
}

#vid {
    margin: 50px auto 30px;
    border-radius: 50%;
    height: 166px;
    width: 166px;
}

#vid-text {
    margin: 35px auto 0;
    color: #262626;
    font-size: 23px;
    text-align: left;
    width: 80%;
    line-height: 42px;
}
