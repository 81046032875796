.slide-to-unlock {
    position: relative;
    margin-top: 20px;
    height: 70px;
  }

.slide-to-unlock-bc {
    position: absolute;
    width: 100%;
    max-width: 330px;
    height: 70px;
    background-color: #ffffff42;
    box-shadow: 3px 3px 24px -1px #bfbfbf3d;
    border-radius: 26px;
    overflow: hidden;
    z-index: 9;
}
  
  .slider {
    position: absolute;
    top: 3px;
    left: 0;
  }
  
  .unlock-text {
      position: absolute;
      top: 0;
      left: 35px;
      right: 0;
      margin: auto;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      text-shadow: 0 3px 6px #0000002e;
  }
